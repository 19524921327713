<template>
  <div :class="{ 'd-none': !loader }" class="loading newloader">
    <div id="app">
        <v-progress-linear
          :active="loader"
          :indeterminate="loader"
          absolute
          top
          color="#2758f6"
        ></v-progress-linear>
</div>
  </div>
</template>

<script>

export default {
  name: "Loader",
  props: ["show"],
  mounted() {
  },
  destroyed() {
    clearInterval(window.loadingSlides);
  },
    computed: {
    loader() {
      return this.$store.state.custom.show_loader;
    },
  },
  methods: {
  },
};
</script>
